<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("Addtransfer") }}
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row text-sm-start d-flex justify-content-center">
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label">{{
              $t("documentnumber")
            }}</label>
            <input disabled class="form-control mb-4" />
          </div>
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label">{{
              $t("documentdate")
            }}</label>
            <input v-model="date" disabled class="form-control mb-4" />
          </div>
        </div>

        <div class="row text-sm-start d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label required">{{
              $t("transferfrom")
            }}</label>
            <select class="form-select" v-model="form.fromWarehouseId">
              <option v-for="item in warehouses" :key="item" :value="item.id">
                {{ item.warehouse_code }} | {{ item.warehouse_short_name }} |
                {{ item.warehouse_full_name }}
              </option>
            </select>
          </div>

          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label required">{{
              $t("transfertowarehouse")
            }}</label>
            <select class="form-select" v-model="form.toWarehouseId">
              <option v-for="item in warehouses" :key="item" :value="item.id">
                {{ item.warehouse_code }} | {{ item.warehouse_short_name }} |
                {{ item.warehouse_full_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row text-sm-start d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <label for="selectMainProduct" class="form-label">{{
              $t("annotation")
            }}</label>
            <input v-model="form.remark" class="form-control mb-4" />
          </div>
        </div>

        <div class="row text-end">
          <div class="col-sm-12">
            <button
              type="button"
              class="btn btn-sm btn-light btn-success"
              style="font-size: 14px; font-weight: bold"
              @click="openDialogSearchProcuct()"
            >
              {{ $t("addon") }}
            </button>
            <!-- <AddItem
              :dialogAddItem="dialogAddItem"
              :warehouseId="form.fromWarehouseId"
              @addProduct="addProduct"
              @closeDialogAddItem="closeDialogAddItem"
            /> -->
            <DialogSearchProduct
              :dialogSearchProduct="dialogSearchProduct"
              @closeDialogSearchProduct="closeDialogSearchProduct"
              @submitSearchProduct="submitSearchProduct"
            />
          </div>
        </div>

        <div class="table-responsive" style="padding-top: 3px">
          <table
            class="table table-hover table-rounded table-striped border gy-7 gs-7"
          >
            <thead>
              <tr
                style="width: 100%"
                class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
              >
                <th>#</th>
                <th>{{ $t("itemcode") }}</th>
                <th>{{ $t("itemname") }}</th>
                <th>{{ $t("amount") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="dataItemsShow.length < 1">
                <td class="text-center" colspan="6">ไม่มีข้อมูล</td>
              </tr>
              <tr v-for="(item, index) in dataItemsShow" :key="index">
                <td></td>
                <td>{{ item.productCode }}</td>
                <td>{{ item.productName }}</td>
                <td>{{ item.requestStockAmt }} {{ item.unitName }}</td>
                <td>
                  <button
                    @mouseenter="hoverBBtn"
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                    @click="goToDelete(item, index)"
                  >
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- <div class="row d-flex justify-content-center mt-4">
          <div class="col-sm-8">
            <label for="selectMainProduct" class="form-label">หมายเหตุ</label>
            <input class="form-control mb-4" />
            <label for="selectMainProduct" class="form-label"
              >โอนเข้าคลัง</label
            >
            <input class="form-control mb-4" />
          </div>

          <div class="col-sm-4">
            <label for="selectMainProduct" class="form-label"
              >มูลค่ารวม(ก่อนVAT)</label
            >
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label">ส่วนลดรวม</label>
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label">VAT</label>
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label"
              >มูลค่าสุทธิ</label
            >
            <input disabled class="form-control" />
          </div>
        </div> -->
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-light btn-primary me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submittransaction()"
          >
            <!-- @click="submit()" -->
            {{ $t("save") }}
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            style="font-size: 14px; font-weight: bold"
            @click="cancel"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import AddItem from "./components/dialogAddItem.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";
import transferApi from "@/api/transfer/";
import DialogSearchProduct from "./components/dialogSearchProduct.vue";
import moment from "moment";

export default {
  components: {
    AddItem,
    DialogSearchProduct,
  },
  setup() {
    document.title = "J&N | สร้างใบโอนสินค้า";
  },
  data: () => ({
    searchProduct: "",
    isSubmit: false,
    isValidRetailPrice: false,
    dialogAddItem: false,
    form: {
      fromWarehouseId: 0,
      toWarehouseId: 0,
      remark: "",
    },
    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
    warehouses: [],
    dataItemsShow: [],
    dialogSearchProduct: false,
    date: moment(new Date()).format("DD/MM/YYYY | HH:mm น."),
  }),

  created() {
    this.getAllWarehouse();
  },
  methods: {
    // addProduct(item) {
    //   console.log("addProduct", item);
    //   this.dataItemsShow.push(item);
    // },
    submitSearchProduct(item) {
      console.log("addProduct", item);
      let body = {
        id: item.productId,
        productCode: item.productCode,
        productName: item.productName,
        requestStockAmt: item.requestStockAmt,
        unitName: item.unitName,
      };
      this.dataItemsShow.push(body);
    },
    async getAllWarehouse() {
      let responseData = [];
      try {
        responseData = await whApi.warehouse.getAll(
          localStorage.getItem("branchId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.warehouses = responseData.data;
      }
    },
    async submit() {
      await this.validateForm();
      this.isSubmit = true;
      let responseData = [];
      let body = {
        ...this.form,
        products: this.dataItemsShow,
      };

      try {
        responseData = await whApi.warehouse.updatestock(body);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        // this.productstock = responseData.data[0];
        await this.submittransaction();
      }
    },
    async submittransaction() {
      if (!this.form.fromWarehouseId) {
        Swal.fire({
          icon: "error",
          title: "กรุณาระบุคลังโอนสินค้า",
          showConfirmButton: true,
        });
      } else {
        if (!this.form.toWarehouseId) {
          Swal.fire({
            icon: "error",
            title: "กรุณาระบุคลังรับสินค้า",
            showConfirmButton: true,
          });
        } else {
          if (this.dataItemsShow.length > 0) {
            Swal.fire({
              icon: "question",
              title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
              confirmButtonText: `${this.$t("ok")} `,
              cancelButtonText: `${this.$t("cancel")} `,
              showCancelButton: true,
            }).then(async (result) => {
              if (result.isConfirmed) {
                let responseData = [];
                let body = {
                  transaction_number: "",
                  form_warehouse_id: this.form.fromWarehouseId,
                  to_warehouse_id: this.form.toWarehouseId,
                  products: this.dataItemsShow,
                  remark: this.form.remark,
                  status: "รอจัดของ",
                  companyId: localStorage.getItem("companyId"),
                };
                try {
                  responseData = await transferApi.transfer.create(body);
                } catch (error) {
                  console.log(error);
                }
                if (responseData.response_status === "SUCCESS") {
                  // this.productstock = responseData.data[0];
                  Swal.fire({
                    icon: "success",
                    title: "บันทึกข้อมูลสำเร็จ",
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                  }).then(() => {
                    this.$router.push("/sale/SA4-1");
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: responseData.message,
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                  });
                }
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "กรุณาเลือกสินค้าอย่างน้อย 1 รายการ",
              showConfirmButton: true,
            });
          }
        }
      }
    },

    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2delete")} ${item.productCode} ${this.$t(
          "ornot"
        )} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      this.dataItemsShow.splice(index, 1);
    },
    cancel() {
      this.$router.go(-1);
    },
    addItem() {
      this.dialogAddItem = true;
    },
    closeDialogAddItem() {
      this.dialogAddItem = false;
    },
    openDialogSearchProcuct() {
      if (this.form.fromWarehouseId) {
        this.dialogSearchProduct = true;
      } else if (!this.form.fromWarehouseId) {
        Swal.fire({
          icon: "error",
          title: "กรุณาเลือกคลังต้นทาง",
          showConfirmButton: true,
        });
        this.dialogSearchProduct = false;
      }
    },
    closeDialogSearchProduct() {
      this.dialogSearchProduct = false;
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    checkFormIsEmpty() {
      !this.form.fromWarehouseId
        ? (this.isValid = false)
        : !this.form.toWarehouseId
        ? (this.isValid = false)
        : !this.dataItemsShow.length > 0
        ? (this.isValid = false)
        : (this.isValid = true);
    },
  },
};
</script>
